import { TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import {
  AccessDeviceAssetModel,
  AccessDeviceAssetPayload,
  AccessDeviceLinksModel,
  AccessDeviceLinksPayload,
  AccessDeviceMetaModel,
  AccessDeviceMetaPayload,
  AccessDeviceModel,
  AccessDevicePayload,
  AccessDevicesListResponseModel,
  AccessDevicesListResponsePayload,
  AccessDeviceTypeModel,
  AccessDeviceTypeType,
  AccessDeviceTypeVendor,
  CreateQRActiveModel,
  CreateQRActivePayload,
  QRActiveDataPayload,
  QRActiveModel,
} from '../models';

const payloadToAccessDeviceAsset = ({
  alias,
  asset_id,
  block_number,
  city,
  country,
  door,
  flat,
  province,
  staircase,
  street_name,
  street_type,
  type,
  zip_code,
}: AccessDeviceAssetPayload): AccessDeviceAssetModel => {
  return {
    alias: alias ?? null,
    assetId: asset_id ?? '',
    blockNumber: block_number ?? '',
    city: city ?? '',
    country: country ?? '',
    door: door ?? null,
    flat: flat ?? null,
    province: province ?? '',
    staircase: staircase ?? null,
    streetName: street_name ?? '',
    streetType: street_type ?? '',
    type: type ?? '',
    zipCode: zip_code ?? '',
  };
};

const accessDeviceTypeManager = {
  [AccessDeviceTypeType.DOORLOCK_DANALOCK_V3]: {
    name: AccessDeviceTypeType.DOORLOCK_DANALOCK_V3,
    vendor: AccessDeviceTypeVendor.DANALOCK,
    model: 'V3',
  },
  [AccessDeviceTypeType.DOORLOCK_ILOCK18]: {
    name: AccessDeviceTypeType.DOORLOCK_ILOCK18,
    vendor: AccessDeviceTypeVendor.ISURPASS,
    model: 'ILOCK18',
  },
  [AccessDeviceTypeType.DOORLOCK_RAYONICS_ECYLINDER_EXCL]: {
    name: AccessDeviceTypeType.DOORLOCK_RAYONICS_ECYLINDER_EXCL,
    vendor: AccessDeviceTypeVendor.RAYONICS,
    model: 'ECYLINDER_EXCL',
  },
  [AccessDeviceTypeType['DOORLOCK_TESA_SMARTair_i-max']]: {
    name: AccessDeviceTypeType['DOORLOCK_TESA_SMARTair_i-max'],
    vendor: AccessDeviceTypeVendor.TESA,
    model: 'SMARTair_i-max',
  },
  [AccessDeviceTypeType['DOORLOCK_VIANS_VI-BC10B']]: {
    name: AccessDeviceTypeType['DOORLOCK_VIANS_VI-BC10B'],
    vendor: AccessDeviceTypeVendor.VIANS,
    model: 'VI-BC10B',
  },
  [AccessDeviceTypeType.DOORSENSOR_AEOTEC_ZWA008]: {
    name: AccessDeviceTypeType.DOORSENSOR_AEOTEC_ZWA008,
    vendor: AccessDeviceTypeVendor.AEOTEC,
    model: 'ZWA008',
  },
  [AccessDeviceTypeType.DOORSENSOR_GOODWAY_TS2001Z1]: {
    name: AccessDeviceTypeType.DOORSENSOR_GOODWAY_TS2001Z1,
    vendor: AccessDeviceTypeVendor.GOODWAY,
    model: 'TS2001Z1',
  },
  [AccessDeviceTypeType['DOORSENSOR_PHILIO_PST02-1C']]: {
    name: AccessDeviceTypeType['DOORSENSOR_PHILIO_PST02-1C'],
    vendor: AccessDeviceTypeVendor.PHILIO,
    model: 'PST02-1C',
  },
  [AccessDeviceTypeType.DOORSENSOR_TKB_TSM10]: {
    name: AccessDeviceTypeType.DOORSENSOR_TKB_TSM10,
    vendor: AccessDeviceTypeVendor.TKB,
    model: 'TSM10',
  },
  [AccessDeviceTypeType['DOORSENSOR_ZIPATO_PH-PSM01.EU']]: {
    name: AccessDeviceTypeType['DOORSENSOR_ZIPATO_PH-PSM01.EU'],
    vendor: AccessDeviceTypeVendor.ZIPATO,
    model: 'PH-PSM01.EU',
  },
  [AccessDeviceTypeType.DUMMY_LOCK]: {
    name: AccessDeviceTypeType.DUMMY_LOCK,
    vendor: AccessDeviceTypeVendor.ALFRED,
    model: 'Virtual-Lock',
  },
  [AccessDeviceTypeType.DUMMY_SWITCH]: {
    name: AccessDeviceTypeType.DUMMY_SWITCH,
    vendor: AccessDeviceTypeVendor.ALFRED,
    model: 'Virtual-Switch',
  },
  [AccessDeviceTypeType.GENERIC_COMMUNITY_DOOR]: {
    name: AccessDeviceTypeType.GENERIC_COMMUNITY_DOOR,
    vendor: AccessDeviceTypeVendor.ALFRED,
    model: 'Generic-Community-Door',
  },
  [AccessDeviceTypeType.GENERIC_LOCK]: {
    name: AccessDeviceTypeType.GENERIC_LOCK,
    vendor: AccessDeviceTypeVendor.ALFRED,
    model: 'Generic-Lock',
  },
  [AccessDeviceTypeType.GENERICSENSOR_AEOTEC_AERQ]: {
    name: AccessDeviceTypeType.GENERICSENSOR_AEOTEC_AERQ,
    vendor: AccessDeviceTypeVendor.AEOTEC,
    model: 'AERQ',
  },
  [AccessDeviceTypeType.GENERICSENSOR_HEIMAN_HS1HTZ]: {
    name: AccessDeviceTypeType.GENERICSENSOR_HEIMAN_HS1HTZ,
    vendor: AccessDeviceTypeVendor.HEIMAN,
    model: 'HS1HTZ',
  },
  [AccessDeviceTypeType.INPUTSENSOR_SAYKEY_JTB300809]: {
    name: AccessDeviceTypeType.INPUTSENSOR_SAYKEY_JTB300809,
    vendor: AccessDeviceTypeVendor.SAYKEY,
    model: 'JTB300809',
  },
  [AccessDeviceTypeType['PLUG_FIBARO_FGWPE/F-102']]: {
    name: AccessDeviceTypeType['PLUG_FIBARO_FGWPE/F-102'],
    vendor: AccessDeviceTypeVendor.FIBARO,
    model: 'FGWPE/F-102',
  },
  [AccessDeviceTypeType['PLUG_NEOCOOLCAM_NAS-WR01ZE']]: {
    name: AccessDeviceTypeType['PLUG_NEOCOOLCAM_NAS-WR01ZE'],
    vendor: AccessDeviceTypeVendor.NEOCOOLCAM,
    model: 'NAS-WR01ZE',
  },
  [AccessDeviceTypeType.PLUG_QUBINO_ZMNHYD1]: {
    name: AccessDeviceTypeType.PLUG_QUBINO_ZMNHYD1,
    vendor: AccessDeviceTypeVendor.QUBINO,
    model: 'ZMNHYD1',
  },
  [AccessDeviceTypeType['PLUG_SIMON_10002041-130']]: {
    name: AccessDeviceTypeType['PLUG_SIMON_10002041-130'],
    vendor: AccessDeviceTypeVendor.SIMON,
    model: '10002041-130',
  },
  [AccessDeviceTypeType.PUSHBUTTON_AEON_ZW088]: {
    name: AccessDeviceTypeType.PUSHBUTTON_AEON_ZW088,
    vendor: AccessDeviceTypeVendor.AEON,
    model: 'ZW088',
  },
  [AccessDeviceTypeType.PUSHBUTTON_AEON_ZW130]: {
    name: AccessDeviceTypeType.PUSHBUTTON_AEON_ZW130,
    vendor: AccessDeviceTypeVendor.AEON,
    model: 'ZW130',
  },
  [AccessDeviceTypeType['PUSHBUTTON_FIBARO_FGPB-101-2']]: {
    name: AccessDeviceTypeType['PUSHBUTTON_FIBARO_FGPB-101-2'],
    vendor: AccessDeviceTypeVendor.FIBARO,
    model: 'FGPB-101-2',
  },
  [AccessDeviceTypeType['PUSHBUTTON_NEOCOOLCAM_NAS-RC01Z']]: {
    name: AccessDeviceTypeType['PUSHBUTTON_NEOCOOLCAM_NAS-RC01Z'],
    vendor: AccessDeviceTypeVendor.NEOCOOLCAM,
    model: 'NAS-RC01Z',
  },
  [AccessDeviceTypeType.SHELLY_SH1]: { name: AccessDeviceTypeType.SHELLY_SH1, vendor: AccessDeviceTypeVendor.SHELLY, model: 'SH1' },
  [AccessDeviceTypeType.SHELLY_SH1PM]: { name: AccessDeviceTypeType.SHELLY_SH1PM, vendor: AccessDeviceTypeVendor.SHELLY, model: 'SH1PM' },
  [AccessDeviceTypeType['SHELLY_SH2.5']]: {
    name: AccessDeviceTypeType['SHELLY_SH2.5'],
    vendor: AccessDeviceTypeVendor.SHELLY,
    model: 'SH2.5',
  },
  [AccessDeviceTypeType['SWITCH_AEON_ZW139-C']]: {
    name: AccessDeviceTypeType['SWITCH_AEON_ZW139-C'],
    vendor: AccessDeviceTypeVendor.AEON,
    model: 'ZW139-C',
  },
  [AccessDeviceTypeType['SWITCH_FIBARO_FGBS-222']]: {
    name: AccessDeviceTypeType['SWITCH_FIBARO_FGBS-222'],
    vendor: AccessDeviceTypeVendor.FIBARO,
    model: 'FGBS-222',
  },
  [AccessDeviceTypeType['SWITCH_FIBARO_FGS-213']]: {
    name: AccessDeviceTypeType['SWITCH_FIBARO_FGS-213'],
    vendor: AccessDeviceTypeVendor.FIBARO,
    model: 'FGS-213',
  },
  [AccessDeviceTypeType['SWITCH_FIBARO_FGS-214']]: {
    name: AccessDeviceTypeType['SWITCH_FIBARO_FGS-214'],
    vendor: AccessDeviceTypeVendor.FIBARO,
    model: 'FGS-214',
  },
  [AccessDeviceTypeType['SWITCH_FIBARO_FGS-222']]: {
    name: AccessDeviceTypeType['SWITCH_FIBARO_FGS-222'],
    vendor: AccessDeviceTypeVendor.FIBARO,
    model: 'FGS-222',
  },
  [AccessDeviceTypeType['SWITCH_FIBARO_FGS-223']]: {
    name: AccessDeviceTypeType['SWITCH_FIBARO_FGS-223'],
    vendor: AccessDeviceTypeVendor.FIBARO,
    model: 'FGS-223',
  },
  [AccessDeviceTypeType['SWITCH_FIBARO_FGS-224']]: {
    name: AccessDeviceTypeType['SWITCH_FIBARO_FGS-224'],
    vendor: AccessDeviceTypeVendor.FIBARO,
    model: 'FGS-224',
  },
  [AccessDeviceTypeType.SWITCH_GOODWAY_TD13010Z1]: {
    name: AccessDeviceTypeType.SWITCH_GOODWAY_TD13010Z1,
    vendor: AccessDeviceTypeVendor.GOODWAY,
    model: 'TD13010Z1',
  },
  [AccessDeviceTypeType.SWITCH_PHILIO_PAN03]: {
    name: AccessDeviceTypeType.SWITCH_PHILIO_PAN03,
    vendor: AccessDeviceTypeVendor.PHILIO,
    model: 'PAN03',
  },
  [AccessDeviceTypeType.SWITCH_PHILIO_PAN04]: {
    name: AccessDeviceTypeType.SWITCH_PHILIO_PAN04,
    vendor: AccessDeviceTypeVendor.PHILIO,
    model: 'PAN04',
  },
  [AccessDeviceTypeType.SWITCH_PHILIO_PAN05]: {
    name: AccessDeviceTypeType.SWITCH_PHILIO_PAN05,
    vendor: AccessDeviceTypeVendor.PHILIO,
    model: 'PAN05',
  },
  [AccessDeviceTypeType.SWITCH_PHILIO_PAN06]: {
    name: AccessDeviceTypeType.SWITCH_PHILIO_PAN06,
    vendor: AccessDeviceTypeVendor.PHILIO,
    model: 'PAN06',
  },
  [AccessDeviceTypeType.SWITCH_QUBINO_ZMNHAD1]: {
    name: AccessDeviceTypeType.SWITCH_QUBINO_ZMNHAD1,
    vendor: AccessDeviceTypeVendor.QUBINO,
    model: 'ZMNHAD1',
  },
  [AccessDeviceTypeType.SWITCH_QUBINO_ZMNHBD1]: {
    name: AccessDeviceTypeType.SWITCH_QUBINO_ZMNHBD1,
    vendor: AccessDeviceTypeVendor.QUBINO,
    model: 'ZMNHBD1',
  },
  [AccessDeviceTypeType.SWITCH_QUBINO_ZMNHND1]: {
    name: AccessDeviceTypeType.SWITCH_QUBINO_ZMNHND1,
    vendor: AccessDeviceTypeVendor.QUBINO,
    model: 'ZMNHND1',
  },
  [AccessDeviceTypeType['SWITCH_SIMON_10002034-130']]: {
    name: AccessDeviceTypeType['SWITCH_SIMON_10002034-130'],
    vendor: AccessDeviceTypeVendor.SIMON,
    model: '10002034-130',
  },
  [AccessDeviceTypeType.SWITCH_TKB_TZ74]: { name: AccessDeviceTypeType.SWITCH_TKB_TZ74, vendor: AccessDeviceTypeVendor.TKB, model: 'TZ74' },
  [AccessDeviceTypeType.SWITCH_TKB_TZ78]: { name: AccessDeviceTypeType.SWITCH_TKB_TZ78, vendor: AccessDeviceTypeVendor.TKB, model: 'TZ78' },
  [AccessDeviceTypeType.SWITCH_TKB_TZ79]: { name: AccessDeviceTypeType.SWITCH_TKB_TZ79, vendor: AccessDeviceTypeVendor.TKB, model: 'TZ79' },
};

const payloadToAccessDeviceType = (device_type: string): AccessDeviceTypeModel => {
  return accessDeviceTypeManager[device_type as AccessDeviceTypeType];
};

const payloadToAccessDevice = ({
  asset,
  battery_level,
  device_type,
  name,
  room,
  shared,
  access_sensor_id,
}: AccessDevicePayload): AccessDeviceModel => {
  const transformedAsset = !!asset ? payloadToAccessDeviceAsset(asset) : null;
  const transformedDeviceType = !!device_type ? payloadToAccessDeviceType(device_type) : null;

  return {
    asset: transformedAsset,
    batteryLevel: battery_level ?? null,
    deviceType: transformedDeviceType,
    name: name ?? null,
    room: room ?? null,
    shared: shared ?? false,
    accessSensorId: access_sensor_id ?? '',
  };
};

const payloadToAccessDeviceMeta = ({ all_results, page_size, results, total_pages }: AccessDeviceMetaPayload): AccessDeviceMetaModel => {
  return {
    allResults: all_results ?? 0,
    pageSize: page_size ?? TABLE_PAGINATION_SIZE_DEFAULT,
    results: results ?? 0,
    totalPages: total_pages ?? 1,
  };
};

const payloadToAccessDeviceLinks = ({ first, last, next, prev, self }: AccessDeviceLinksPayload): AccessDeviceLinksModel => {
  return {
    first: first ?? '',
    last: last ?? '',
    next: next ?? '',
    prev: prev ?? '',
    self: self ?? '',
  };
};

export const payloadToAccessDevicesListByProjectIdResponse = ({
  data,
  meta,
  links,
}: AccessDevicesListResponsePayload): AccessDevicesListResponseModel => {
  const transformedData: AccessDeviceModel[] = data.map((accessDevice) => payloadToAccessDevice(accessDevice)) ?? [];
  const transformedMeta: AccessDeviceMetaModel = payloadToAccessDeviceMeta(meta);
  const transformedLinks: AccessDeviceLinksModel = payloadToAccessDeviceLinks(links);

  return {
    data: transformedData,
    meta: transformedMeta,
    links: transformedLinks,
  };
};

export const payloadToQRActive = ({ data }: { data: QRActiveDataPayload }): QRActiveModel => {
  const {
    created_by,
    qr,
    status,
    uid,
    valid_from,
    valid_until
  } = data;
  return {
    createdBy: created_by,
    qr,
    status,
    uid,
    validFrom: valid_from,
    validUntil: valid_until,
  }
};

export const qrActiveToPayload = ({ assetId, data }: {
  assetId: string;
  data: CreateQRActiveModel;
}): CreateQRActivePayload => {
  const { qrCode, validFrom, validUntil } = data;
  return {
    qr_code: qrCode,
    valid_from: validFrom,
    valid_until: validUntil,
    allocations: [
      {
        asset_id: assetId
      }
    ]
  }
}
