import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { QRActiveDataPayload, QRActiveModel } from '../models';
import { payloadToQRActive } from '../mappers';

const getUrl = ({
  host,
  assetId,
}: {
  host: string;
  assetId: string;
}) => {
  return `${host}/qr/access/assets/${assetId}`;
};

export const GetQRActiveByAssetId = async ({
  host,
  token,
  assetId,
}: {
  host: string;
  token: string;
  assetId: string;
}): Promise<QRActiveModel> => {
  try {
    const url = getUrl({ host, assetId });
    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<{ data: QRActiveDataPayload }>;
    return payloadToQRActive(data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
