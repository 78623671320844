import { FC } from 'react';
import DrawerAtom from 'components/atoms/DrawerAtom';
import TextAtom from 'components/atoms/TextAtom';
import { COLORS } from 'constants/colors';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { Collapse, DatePicker, Divider, Form, Tag } from 'antd';
import InputAtom from 'components/atoms/InputAtom';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { ManualQRAccessInitialStatesModel } from 'components/pages/AssetPage/Tabs/TabAccesses/resources/useManualQRAccess/models';
import './styles.scss';

const { Panel } = Collapse;

const DRAWER_ATOM_WIDTH = '480px';

export enum ManualQRAccessInputFormKey {
  RANGE_DATE = 'rangeDate',
  QR_CODE = 'QRCode',
}

interface Props extends ManualQRAccessInitialStatesModel {
  title: string;
  closeButtonText: string;
  saveButtonText: string;
  assetInfoText: string;
  assetNameText: string;
  assetAddressText: string;
  expiredText: string;
  onClose: () => void;
  onSave: () => void;
  isLoading: boolean;
  isVisible: boolean;
  isExpiredQRCode: boolean;
}

const ManualQRAccessDrawer: FC<Props> = ({
  title,
  rangeDate,
  QRCode,
  closeButtonText,
  saveButtonText,
  assetInfoText,
  assetNameText,
  assetAddressText,
  expiredText,
  onClose,
  onSave,
  isLoading,
  isVisible,
  isExpiredQRCode,
}) => {
  return (
    <DrawerAtom
      width={DRAWER_ATOM_WIDTH}
      className={'ManualQRAccessDrawer__wrapper'}
      title={
        <TextAtom
          className={'ManualQRAccessDrawer__title'}
          style={{ color: COLORS.text }}
        >{title}</TextAtom>
      }
      destroyOnClose={true}
      closable={!isLoading}
      maskClosable={!isLoading}
      onClose={onClose}
      placement={'right'}
      size={'large'}
      open={isVisible}
    >
      <main className={'ManualQRAccessDrawer__main'}>
        <div className={'ManualQRAccessDrawer__main-wrapper'}>
          <div className={'ManualQRAccessDrawer__main-wrapper-input'}>
            <div className={'ManualQRAccessDrawer__input-label-range-date'}>{`${rangeDate.label} ${rangeDate.isRequired && '*'}`}</div>
            <Form.Item
              validateStatus={rangeDate.isError ? 'error' : undefined}
              help={rangeDate.errorMessage}
            >
              {!isLoading && (
                <DatePicker.RangePicker
                  className={'ManualQRAccessDrawer__main-wrapper-input-range-picker'}
                  format={rangeDate.formatDate}
                  allowClear
                  defaultValue={rangeDate.values as any}
                  onChange={rangeDate.onChangeRange as any}
                />
              )}
              {isLoading && (
                <DatePicker.RangePicker
                  className={'ManualQRAccessDrawer__main-wrapper-input-range-picker'}
                  format={rangeDate.formatDate}
                  disabled
                />
              )}
              {isExpiredQRCode && (
                <Tag
                  className={'ManualQRAccessDrawer__tag'}
                  color={'red'}
                >{expiredText}</Tag>
              )}
            </Form.Item>
          </div>
          <div>
            <div className={'ManualQRAccessDrawer__input-label-QR-code'}>{`${QRCode.label} ${QRCode.isRequired && '*'}`}</div>
            <Form.Item
              validateStatus={QRCode.isError ? 'error' : undefined}
              help={QRCode.errorMessage}
            >
              <InputAtom
                onChange={(event) => QRCode.onChangeValue(event.target.value)}
                value={QRCode.value}
                placeholder={QRCode.placeholder}
              />
            </Form.Item>
          </div>
        </div>
        {assetNameText && assetAddressText && (
          <div>
            <Divider />
            <Collapse>
              <Panel header={assetInfoText} key='1'>
                <p>{assetNameText}</p>
                <p>{assetAddressText}</p>
              </Panel>
            </Collapse>
          </div>
        )}
      </main>
      <footer>
        <DefaultButton
          disabled={isLoading}
          onClick={onClose}
          title={closeButtonText} />
        <PrimaryButton
          loading={isLoading}
          onClick={onSave}
          title={saveButtonText}
        />
      </footer>
    </DrawerAtom>
  );
}

export default ManualQRAccessDrawer;
