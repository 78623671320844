import { Reducer, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Action, ManualQRAccessInitialStatesModel } from './models';
import { ManualQRAccessInputFormKey } from 'components/organisms/ManualQRAccessDrawer';
import { INITIAL_QR_CODE, INITIAL_RANGE, sendMessageError } from './utils';
import { GetQRActiveByAssetId } from 'core/domain/access/repository/getQRActiveByAssetId';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import moment from 'moment';
import {  TIME_FORMAT } from 'constants/date';
import { CreateQRActiveByAssetId } from 'core/domain/access/repository/createQRActiveByAssetId';
import { CreateQRActiveModel } from 'core/domain/access/models';
import { hasPermission } from 'services/permissions';
import { UserPermissionType } from 'models/users.model';

const mutualidad = {
  FLOOR_1: 'D5FJN2WBTXK93',
  FLOOR_2: 'DPNE4M27ATJ9A',
  FLOOR_3: 'DE2DJ4P8VGMB3',
  FLOOR_4: 'DRVTAK53J7F32',
  FLOOR_5: 'D6DEKCNQXT824',
  FLOOR_6: 'DWHV9MES4J73E',
  FLOOR_7: 'DJTR4S8BAYW55',
  FLOOR_8: 'D7KXNYH43S82E',
  GROUND_FLOOR: 'DSM9RG4KJLB77',
  BASEMENT: 'DSLGNP4XK96EA',
};

const floorList = [
  mutualidad.FLOOR_1,
  mutualidad.FLOOR_2,
  mutualidad.FLOOR_3,
  mutualidad.FLOOR_4,
  mutualidad.FLOOR_5,
  mutualidad.FLOOR_6,
  mutualidad.FLOOR_7,
  mutualidad.FLOOR_8,
  mutualidad.GROUND_FLOOR,
  mutualidad.BASEMENT,
];

export const manualQRAccessInitialStates: ManualQRAccessInitialStatesModel = {
  [ManualQRAccessInputFormKey.RANGE_DATE]: INITIAL_RANGE,
  [ManualQRAccessInputFormKey.QR_CODE]: INITIAL_QR_CODE,
};

const reducer = (state: ManualQRAccessInitialStatesModel, action: Action) => ({ ...state, [action.key]: action.payload });

export const useManualQRAccess = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assetNameText, setAssetNameText] = useState<string>('');
  const [assetAddressText, setAssetAddressText] = useState<string>('');
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [
    { QRCode, rangeDate }, dispatch
  ] = useReducer<Reducer<ManualQRAccessInitialStatesModel, Action>>(
    reducer, manualQRAccessInitialStates
  );

  const titleText = t('_ACCESS_MANUAL_QR_ACCESS_DRAWER_TEXT_TITLE');
  const assetInfoText = t('_ACCESS_MANUAL_QR_ACCESS_DRAWER_TEXT_ASSET_INFO');
  const closeButtonText = t('_ACCESS_MANUAL_QR_ACCESS_BUTTON_TEXT_CLOSE');
  const saveButtonText = t('_ACCESS_MANUAL_QR_ACCESS_BUTTON_TEXT_SAVE');
  const manualQRAccessItemMenuText = t('_ACCESS_MANUAL_QR_ACCESS_ITEM_MENU_QR_CODE');
  const expiredText = t('_ACCESS_MANUAL_QR_ACCESS_DRAWER_TEXT_EXPIRED');

  const onSetIsExpired = (validUntil: number) => {
    setIsExpired(moment().unix() > validUntil);
  };

  const getData = () => {
    GetQRActiveByAssetId({ assetId, host, token })
      .then(({ qr, validFrom, validUntil }) => {
        onSetIsExpired(validUntil);
        dispatch({
          key: ManualQRAccessInputFormKey.QR_CODE,
          payload: {
            ...QRCode,
            value: qr,
          }
        });
        dispatch({
          key: ManualQRAccessInputFormKey.RANGE_DATE,
          payload: {
            ...rangeDate,
            values: [
              moment(moment.unix(validFrom), TIME_FORMAT),
              moment(moment.unix(validUntil), TIME_FORMAT)
            ],
          }
        });
      })
      .catch((error) => sendMessageError(error.code))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isVisible) {
      setIsLoading(true);
      getData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  
  const onUpdateManualQRAccess = () => {
    setIsVisible(true);
  };

  const onClose = () => {
    dispatch({
      key: ManualQRAccessInputFormKey.QR_CODE,
      payload: {
        ...QRCode,
        isError: false,
        errorMessage: '',
      }
    });
    dispatch({
      key: ManualQRAccessInputFormKey.RANGE_DATE,
      payload: {
        ...rangeDate,
        isError: false,
        errorMessage: '',
      }
    });
    setIsVisible(false);
  };

  const onSaveQRCode = () => {
    setIsLoading(true);
    const qrActiveData: CreateQRActiveModel = {
      qrCode: QRCode.value,
      validFrom: rangeDate.values[0].unix(),
      validUntil: rangeDate.values[1].unix(),
    };
    CreateQRActiveByAssetId({ assetId, host, token, qrActiveData })
      .then(() => onClose())
      .catch((error) => sendMessageError(error.code))
      .finally(() => setIsLoading(false));
  }

  const onSave = () => {
    const isQrCodeEmpty = !QRCode.value;
    const isRangeDateEmpty = !rangeDate.values.length;

    let isEmpty = isQrCodeEmpty && isRangeDateEmpty;

    if (isEmpty) {
      dispatch({
        key: ManualQRAccessInputFormKey.QR_CODE,
        payload: {
          ...QRCode,
          isError: true,
          errorMessage: t('_ACCESS_MANUAL_QR_ACCESS_ITEM_MESSAGE_ERROR_QR_CODE'),
        }
      });
      dispatch({
        key: ManualQRAccessInputFormKey.RANGE_DATE,
        payload: {
          ...rangeDate,
          isError: true,
          errorMessage: t('_ACCESS_MANUAL_QR_ACCESS_ITEM_MESSAGE_ERROR_RANGE'),
        }
      });
    };

    !isEmpty && onSaveQRCode();
  };

  const onChangeRange = (event: moment.Moment[]) => {
    dispatch({
      key: ManualQRAccessInputFormKey.RANGE_DATE,
      payload: {
        ...rangeDate,
        values: event,
      }
    });
  };

  const onChangeValue = (value: string) => {
    dispatch({
      key: ManualQRAccessInputFormKey.QR_CODE,
      payload: {
        ...QRCode,
        value,
      }
    });
  };

  const onSetAssetName = (assetName: string) => {
    setAssetNameText(t('_ACCESS_MANUAL_QR_ACCESS_DRAWER_TEXT_ASSET_NAME', { assetName }));
  };

  const onSetAssetAddress = (assetAddress: string) => {
    setAssetAddressText(t('_ACCESS_MANUAL_QR_ACCESS_DRAWER_TEXT_ASSET_ADDRESS', { assetAddress }));
  };

  rangeDate.onChangeRange = onChangeRange;
  QRCode.onChangeValue = onChangeValue;

  const isManualQRAccessVisible = hasPermission([
    UserPermissionType.UPDATE_QR_CODE_ACCESS,
    UserPermissionType.READ_QR_CODE_ACCESS,
    UserPermissionType.CREATE_QR_CODE_ACCESS,
  ]) && floorList.includes(assetId);

  return {
    title: titleText,
    closeButtonText,
    saveButtonText,
    rangeDate,
    QRCode,
    manualQRAccessItemMenuText,
    assetInfoText,
    assetNameText,
    assetAddressText,
    expiredText,
    onUpdateManualQRAccess,
    onSetAssetNameManualQRAccess: onSetAssetName,
    onSetAssetAddressManualQRAccess: onSetAssetAddress,
    onClose,
    onSave,
    isVisible: isVisible,
    isManualQRAccessVisible,
    isLoading,
    isExpiredQRCode: isExpired,
  }
}